/**
 * @file UpdateVehicleDrawer.tsx
 * Drawer for updating / viewing existing vehicles.
 *
 * components:
 *  UpdateVehicleDrawer
 *
 * author: jackv
 */

import React, { useCallback, useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import { useQuery, useMutation } from "@apollo/client";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";

import { Box, CircularProgress, Typography } from "@mui/material";
import VehicleHeaderUpdateBlock from "../VehicleHeaderUpdateBlock";
import RemoveDialog from "../../RemoveDialog";
import UpgradePlanDialog from "../../UpgradePlanDialog";
import UpdateDrawer from "../../globals/UpdateDrawer";
import { useSnackbar } from "../../../globals/hooks/useSnackbar";
import {
  Vehicle,
  VehicleFeature,
  VehiclePhoto,
  VehicleSettings,
  PlanVariant,
} from "../../../types";
import {
  LOAD_VEHICLE_QUERY,
  UPDATE_VEHICLE_MUTATION,
  REMOVE_VEHICLE_MUTATION,
  ADD_VEHICLE_TO_DUDA_WEBSITE,
  LOAD_VEHICLES_NAME_AND_ID_QUERY,
} from "../../../globals/graphql";
import GQLQueryStatusIndicator from "../../GQLQueryStatusIndicator";
import {
  CopyIcon,
  TrashIcon,
  WarningTriangleIcon,
  WebsiteBrowserIcon,
} from "../../../design-system/icons";
import { errorRed, grayDark, moovsBlue } from "../../../design-system/colors";
import CommentUpdateBlock from "../../CommentUpdateBlock";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
} from "../../../globals/hooks";
import { getUpdateVehiclePricingErrors } from "./util";
import {
  BookingToolSettings,
  Details,
  Features,
  Pricing,
  VehicleTabs,
} from "./components";
import { VehicleTabViewVariant } from "./components/VehicleTabViews/types";
import { VehiclePricingError } from "../types";
import { VehiclePricingInitialErrors } from "../constant";

export type HistoryState = {
  clonedVehicle?: Partial<Vehicle>;
  clonedPhotos?: VehiclePhoto[];
  clonedFeatures?: VehicleFeature[];
  clonedSettings?: VehicleSettings;
};

function UpdateVehicleDrawer() {
  // constants
  const MAX_FREE_PLAN_VEHICLES_COUNT = 10;

  // hooks
  const history = useHistory<HistoryState>();
  const snackbar = useSnackbar();
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { track } = useAnalytics();
  const operator = useOperator();
  const { isMobileView } = useScreenSize();

  // state
  const [features, setFeatures] = useState<VehicleFeature[]>(null);
  const [photos, setPhotos] = useState<VehiclePhoto[]>(null);
  const [childSeats, setChildSeats] =
    useState<
      Pick<
        VehicleSettings,
        "rearFacingSeat" | "forwardFacingSeat" | "boosterSeat"
      >
    >(null);
  const [vehicle, setVehicle] = useState<Partial<Vehicle>>(null);
  const [debouncedVehicle] = useDebounce(vehicle, 500);
  const [vehiclePricingErrors, setVehiclePricingErrors] =
    useState<VehiclePricingError>(VehiclePricingInitialErrors);
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "loading" | "saved" | "error"
  >("default");
  const [publishedIndicatorState, setPublishedIndicatorState] = useState<
    "default" | "publishing" | "published" | "error"
  >("default");
  const [removeVehicleDialogOpen, setRemoveVehicleDialogOpen] = useState(false);
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);
  const [vehicleTabView, setVehicleTabView] = useState(
    VehicleTabViewVariant.DETAILS
  );

  const flag = useRef(false);
  const updateFlag = useRef(false);

  // queries
  const {
    data: vehicleData,
    loading: vehicleLoading,
    error: vehicleError,
    refetch: vehicleRefetch,
  } = useQuery(LOAD_VEHICLE_QUERY, {
    variables: {
      id: vehicleId,
    },
    skip: !vehicleId,
    errorPolicy: "all",
  });
  const { data: vehicles } = useQuery(LOAD_VEHICLES_NAME_AND_ID_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const { skipVehicleSelectionEnabled, skipVehicleDefaultVehicleId } =
    operator?.settings || {};

  // mutations
  const [removeVehicle] = useMutation(REMOVE_VEHICLE_MUTATION, {
    refetchQueries: ["loadVehicles"],
    onCompleted() {
      track("vehicle_deleted");
      snackbar.success("Successfully deleted vehicle!");
      history.push("/vehicles");
    },
    onError(error) {
      snackbar.error("Error deleting vehicle");
    },
  });

  const [updateVehicle] = useMutation(UPDATE_VEHICLE_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
      setPublishedIndicatorState("publishing");
      setTimeout(() => {
        // arbitrary timeout to simulate publishing "time"
        setPublishedIndicatorState("default");
      }, 3000);
      updateFlag.current = false;
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating vehicle");

      // resets to match cache
      setVehicle({
        id: vehicleData.node.id,
        createdAt: vehicleData.node.createdAt,
        updatedAt: vehicleData.node.updatedAt,
        available: vehicleData.node.available,
        name: vehicleData.node.name,
        capacity: vehicleData.node.capacity,
        licensePlate: vehicleData.node.licensePlate,
        description: vehicleData.node.description,
        weekendHourlyCost: vehicleData.node.weekendHourlyCost,
        weekdayHourlyCost: vehicleData.node.weekdayHourlyCost,
        weekendMinMinutes: vehicleData.node.weekendMinMinutes,
        weekdayMinMinutes: vehicleData.node.weekdayMinMinutes,
        vehicleType: vehicleData.node.vehicleType,
        exteriorColor: vehicleData.node.exteriorColor,
        vinNumber: vehicleData.node.vinNumber,
        minimumTotalBaseRate: vehicleData.node.minimumTotalBaseRate,
        deadheadRatePerMile: vehicleData.node.deadheadRatePerMile,
        tripRatePerMile: vehicleData.node.tripRatePerMile,
        totalDeadheadDurationMinutes:
          vehicleData.node.totalDeadheadDurationMinutes,
        enableBaseRateAutomation: vehicleData.node.enableBaseRateAutomation,
        enableBaseRateAutomationBookingTool:
          vehicleData.node.enableBaseRateAutomationBookingTool,
        publishedToDudaSite: vehicleData.node.publishedToDudaSite,
        settings: vehicleData.node.settings,
      });
      flag.current = false;
      updateFlag.current = false;
    },
  });

  const [addVehicleToDudaWebsite] = useMutation(ADD_VEHICLE_TO_DUDA_WEBSITE, {
    onCompleted() {
      setPublishedIndicatorState("publishing");
      setVehicle({ ...vehicle, publishedToDudaSite: true });
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error publishing vehicle");
    },
  });

  // event handlers
  const handleDebouncedVehicleInfoUpdate = useCallback(() => {
    if (!isEqual(debouncedVehicle, vehicle)) return;
    setSaveIndicatorState("loading");

    const errors = getUpdateVehiclePricingErrors(vehicle);

    if (!isEmpty(errors)) {
      setVehiclePricingErrors(errors);
      setSaveIndicatorState("error");
      return;
    } else {
      setVehiclePricingErrors(VehiclePricingInitialErrors);
    }

    if (!updateFlag.current) {
      updateFlag.current = true;

      const input = {
        id: vehicleId,
        available: debouncedVehicle.available,
        name: debouncedVehicle.name,
        typeSlug: debouncedVehicle.vehicleType.typeSlug,
        capacity: Number(debouncedVehicle.capacity),
        licensePlate: debouncedVehicle.licensePlate,
        description: debouncedVehicle.description,
        weekendHourlyCost:
          debouncedVehicle.weekendHourlyCost !== null
            ? Number(debouncedVehicle.weekendHourlyCost)
            : null,
        weekdayHourlyCost:
          debouncedVehicle.weekdayHourlyCost !== null
            ? Number(debouncedVehicle.weekdayHourlyCost)
            : null,
        weekendMinMinutes:
          debouncedVehicle.weekendMinMinutes !== null
            ? Number(debouncedVehicle.weekendMinMinutes)
            : null,
        weekdayMinMinutes:
          debouncedVehicle.weekdayMinMinutes !== null
            ? Number(debouncedVehicle.weekdayMinMinutes)
            : null,
        exteriorColor: debouncedVehicle.exteriorColor,
        vinNumber: debouncedVehicle.vinNumber,
        minimumTotalBaseRate:
          debouncedVehicle.minimumTotalBaseRate !== null
            ? Number(debouncedVehicle.minimumTotalBaseRate)
            : null,
        deadheadRatePerMile:
          debouncedVehicle.deadheadRatePerMile !== null
            ? Number(debouncedVehicle.deadheadRatePerMile)
            : null,
        tripRatePerMile:
          debouncedVehicle.tripRatePerMile !== null
            ? Number(debouncedVehicle.tripRatePerMile)
            : null,
        totalDeadheadDurationMinutes:
          debouncedVehicle.totalDeadheadDurationMinutes !== null
            ? Number(debouncedVehicle.totalDeadheadDurationMinutes)
            : null,
        enableBaseRateAutomation: debouncedVehicle.enableBaseRateAutomation,
        enableBaseRateAutomationBookingTool:
          debouncedVehicle.enableBaseRateAutomationBookingTool,
      };

      updateVehicle({
        variables: {
          input,
        },
      });
    }
  }, [debouncedVehicle, vehicle, updateVehicle, vehicleId]);

  const handleVehiclePhotosChange = (newPhotos: VehiclePhoto[]) => {
    setPhotos([...newPhotos]);
  };

  const handleResetPhotos = () => {
    setPhotos(vehicleData.node.photos);
    vehicleRefetch();
  };

  const handleRemoveVehicle = () => {
    removeVehicle({
      variables: {
        input: {
          id: vehicleId,
        },
      },
    });
    setRemoveVehicleDialogOpen(false);
  };

  const handleDuplicateVehicleClickWithLimitReached = () => {
    track("vehicles_createInitiated", {
      limitRestriction: "limit reached",
    });
    setUpgradePlanDialogOpen(true);
  };

  const handleUpgradePlanClick = () => {
    track("vehicle_upgradePlan");
    history.push("/settings/billing/plans");
  };

  const addVehicleToDudaSite = () => {
    setSaveIndicatorState("loading");
    addVehicleToDudaWebsite({
      variables: {
        input: {
          vehicleId: vehicleId,
        },
      },
    });
  };

  const duplicateVehicle = () => {
    const historyState = {
      clonedVehicle: cloneDeep(vehicle),
      clonedPhotos: cloneDeep(photos),
      clonedFeatures: cloneDeep(features),
      clonedSettings: cloneDeep(vehicle.settings),
    };

    history.push("/vehicles/create", historyState);
  };

  // lifecycle hooks
  // clears vehicle on toggling drawer
  useEffect(() => {
    flag.current = false;
    setVehicle(null);
    setFeatures(null);
    setPhotos(null);
    setChildSeats(null);
    setSaveIndicatorState("default");
    setPublishedIndicatorState("default");
  }, [vehicleId]);

  // sets vehicle on load
  useEffect(() => {
    if (vehicleData && vehicleData.node && !vehicle) {
      flag.current = false;

      setVehicle({
        id: vehicleData.node.id,
        available: vehicleData.node.available,
        createdAt: vehicleData.node.createdAt,
        updatedAt: vehicleData.node.updatedAt,
        name: vehicleData.node.name,
        capacity: vehicleData.node.capacity,
        licensePlate: vehicleData.node.licensePlate,
        description: vehicleData.node.description,
        weekendHourlyCost: vehicleData.node.weekendHourlyCost,
        weekdayHourlyCost: vehicleData.node.weekdayHourlyCost,
        weekendMinMinutes: vehicleData.node.weekendMinMinutes,
        weekdayMinMinutes: vehicleData.node.weekdayMinMinutes,
        vehicleType: vehicleData.node.vehicleType,
        exteriorColor: vehicleData.node.exteriorColor,
        vinNumber: vehicleData.node.vinNumber,
        minimumTotalBaseRate: vehicleData.node.minimumTotalBaseRate,
        deadheadRatePerMile: vehicleData.node.deadheadRatePerMile,
        tripRatePerMile: vehicleData.node.tripRatePerMile,
        totalDeadheadDurationMinutes:
          vehicleData.node.totalDeadheadDurationMinutes,
        enableBaseRateAutomation: vehicleData.node.enableBaseRateAutomation,
        enableBaseRateAutomationBookingTool:
          vehicleData.node.enableBaseRateAutomationBookingTool,
        publishedToDudaSite: vehicleData.node.publishedToDudaSite,
        settings: vehicleData.node.settings,
      });
      setFeatures(vehicleData.node.features);
      setPhotos(vehicleData.node.photos);
      setChildSeats(
        pick(vehicleData.node.settings, [
          "forwardFacingSeat",
          "rearFacingSeat",
          "boosterSeat",
        ])
      );
    }
  }, [vehicleData, vehicle]);

  // reacts to debounced updates
  useEffect(() => {
    if (!debouncedVehicle) {
      return;
    }

    if (!flag.current) {
      flag.current = true;
    } else {
      handleDebouncedVehicleInfoUpdate();
    }
  }, [debouncedVehicle, handleDebouncedVehicleInfoUpdate]);

  const publishedAt = vehicle?.publishedToDudaSite
    ? moment(vehicle.updatedAt).format("LLL")
    : null;

  return (
    <UpdateDrawer
      onClose={() => history.push("/vehicles")}
      createdAt={vehicle ? moment(vehicle.createdAt).format("LLL") : ""}
      updatedAt={vehicle ? moment(vehicle.updatedAt).format("LLL") : ""}
      publishedAt={publishedAt}
      saveIndicatorState={saveIndicatorState}
      publishedIndicatorState={publishedIndicatorState}
      ellipsisMenuOptions={[
        {
          onClick: addVehicleToDudaSite,
          text: vehicle?.publishedToDudaSite
            ? "Already Published"
            : "Publish on Website",
          disableOption: !!vehicle?.publishedToDudaSite,
          icon: <WebsiteBrowserIcon color={grayDark} size="small" />,
        },
        {
          onClick:
            operator?.plan === PlanVariant.Free &&
            vehicles?.loadVehicles.edges.length >= MAX_FREE_PLAN_VEHICLES_COUNT
              ? handleDuplicateVehicleClickWithLimitReached
              : duplicateVehicle,
          text: "Duplicate Vehicle",
          icon: <CopyIcon color={grayDark} size="small" />,
        },
        {
          onClick: () => {
            if (
              vehicle.id === skipVehicleDefaultVehicleId &&
              skipVehicleSelectionEnabled
            ) {
              return snackbar.error(
                <>
                  <Typography variant="subtitle2" color={errorRed}>
                    This is your default vehicle in the customer portal.
                  </Typography>{" "}
                  <Typography variant="body2" color={errorRed} py={0.5}>
                    To delete it, first select a different one from your
                    settings
                  </Typography>
                </>,
                {
                  icon: <WarningTriangleIcon color={errorRed} size="large" />,
                  link: `/settings/customer-portal?tab=settings`,
                  linkLabel: "Go to Settings",
                  linkColor: moovsBlue,
                }
              );
            }
            setRemoveVehicleDialogOpen(true);
          },
          text: "Delete",
          icon: <TrashIcon color={grayDark} size="small" />,
        },
      ]}
      secondHeaderContent={
        <>
          <Box px={isMobileView ? 2 : 4}>
            <VehicleHeaderUpdateBlock
              vehicleId={vehicleId}
              setSaveIndicatorState={setSaveIndicatorState}
            />
          </Box>
          <VehicleTabs
            setVehicleTabView={setVehicleTabView}
            vehicleTabView={vehicleTabView}
          />
        </>
      }
    >
      {vehicleLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      )}
      {vehicleError && !vehicleLoading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GQLQueryStatusIndicator
            name="Vehicle"
            data={vehicleData}
            error={vehicleError}
            refetch={vehicleRefetch}
          />
        </Box>
      )}
      {vehicle && (
        <Box>
          {/* Selected Tab View */}
          {vehicleTabView === VehicleTabViewVariant.DETAILS && (
            <Details
              vehicle={vehicle}
              setVehicle={setVehicle}
              vehiclePricingErrors={vehiclePricingErrors}
              setVehiclePricingErrors={setVehiclePricingErrors}
              photos={photos}
              handleVehiclePhotosChange={handleVehiclePhotosChange}
              handleResetPhotos={handleResetPhotos}
              setSaveIndicatorState={setSaveIndicatorState}
            />
          )}
          {vehicleTabView === VehicleTabViewVariant.FEATURES && (
            <Features
              vehicleId={vehicleId}
              childSeats={childSeats}
              setChildSeats={setChildSeats}
              features={features}
              setFeatures={setFeatures}
              setSaveIndicatorState={setSaveIndicatorState}
            />
          )}
          {vehicleTabView === VehicleTabViewVariant.PRICING && (
            <Pricing
              vehicle={vehicle}
              setVehicle={setVehicle}
              vehiclePricingErrors={vehiclePricingErrors}
              setVehiclePricingErrors={setVehiclePricingErrors}
              setSaveIndicatorState={setSaveIndicatorState}
              setPublishedIndicatorState={setPublishedIndicatorState}
            />
          )}
          {vehicleTabView === VehicleTabViewVariant.BOOKING_TOOL_SETTINGS && (
            <BookingToolSettings
              vehicle={vehicle}
              setVehicle={setVehicle}
              setSaveIndicatorState={setSaveIndicatorState}
              setPublishedIndicatorState={setPublishedIndicatorState}
              vehicleRefetch={vehicleRefetch}
            />
          )}
          <Box mb={3} pt={4}>
            <CommentUpdateBlock
              mode="vehicle"
              comments={vehicleData.node.comments}
              vehicleId={vehicleData.node.id}
              refetchQuery={vehicleRefetch}
              setSaveIndicatorState={setSaveIndicatorState}
            />
          </Box>
          <RemoveDialog
            open={removeVehicleDialogOpen}
            onRemove={handleRemoveVehicle}
            onClose={() => setRemoveVehicleDialogOpen(false)}
            title="Remove this vehicle?"
            body="This will permanantly remove the vehicle. Do you want to remove this
            vehicle?"
          />

          <UpgradePlanDialog
            open={upgradePlanDialogOpen}
            onUpgrade={handleUpgradePlanClick}
            onClose={() => setUpgradePlanDialogOpen(false)}
            body={
              <Typography variant="body2">
                You must upgrade your plan in order to{" "}
                <strong>
                  create more than {MAX_FREE_PLAN_VEHICLES_COUNT} vehicles
                </strong>
                .
              </Typography>
            }
          />
        </Box>
      )}
    </UpdateDrawer>
  );
}

export default UpdateVehicleDrawer;
