import React from "react";
import { Link } from "react-router-dom";

import { Tabs, Tab, Typography } from "@mui/material";

import { primaryMainColor } from "../../theme";
import { grayMedium } from "../../design-system/colors";
import { useScreenSize } from "../../globals/hooks";

type MoovsTabsProps = {
  onChange?: (newValue: any) => void;
  value: string | number;
  tabs: {
    label: string;
    bigLabel?: string;
    value?: string | number;
    disabled?: boolean;
    to?: string;
    Icon?: JSX.Element;
  }[];
  indicatorWidth?: number;
  hideBottomBorder?: boolean;
  isLink?: boolean;
  variant?: "scrollable" | "fullWidth";
};

function MoovsTabs(props: MoovsTabsProps) {
  const {
    onChange,
    value,
    tabs,
    indicatorWidth,
    hideBottomBorder,
    isLink,
    variant,
  } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  const smallIndicatorStyles = {
    "&.MuiTabs-indicator": { width: "114px !important" },
  };

  const styles = {
    "&.Mui-selected": {
      color: primaryMainColor,
    },
    "&.MuiTab-root": {
      fontSize: 14,
      fontWeight: 600,
      minWidth: indicatorWidth !== undefined ? indicatorWidth : 111,
      padding: "0px 10px",
      borderBottom: hideBottomBorder ? "none" : `1px solid ${grayMedium}`,
      minHeight: "48px",
    },
  };

  return (
    <Tabs
      indicatorColor="primary"
      value={value}
      variant={
        variant === "scrollable"
          ? "scrollable"
          : isSmallTabletView || variant === "fullWidth"
          ? "fullWidth"
          : "standard"
      }
      scrollButtons={false}
      {...(onChange && { onChange: (event, newValue) => onChange(newValue) })}
    >
      {tabs.map(({ label, value, bigLabel, disabled, to, Icon }) => {
        const isActive = value === label;

        return (
          <Tab
            sx={[styles, isSmallTabletView && smallIndicatorStyles]}
            value={value}
            key={value}
            disabled={disabled}
            {...(Icon && {
              icon: Icon,
              iconPosition: "start",
            })}
            {...(bigLabel
              ? {
                  icon: <Typography variant="h5">{bigLabel}</Typography>,
                  label: (
                    <Typography
                      variant="overline"
                      {...(isActive && {
                        style: { color: primaryMainColor },
                      })}
                    >
                      {label}
                    </Typography>
                  ),
                }
              : { label })}
            {...(isLink && { component: Link, to })}
          />
        );
      })}
    </Tabs>
  );
}

export default MoovsTabs;
