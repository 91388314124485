import React from "react";

import { Box } from "@mui/material";

import MoovsTabs from "components/globals/MoovsTabs";
import { VehicleTabViewVariant } from "./VehicleTabViews/types";
import { useScreenSize } from "globals/hooks";

type VehicleTabsProps = {
  setVehicleTabView: React.Dispatch<
    React.SetStateAction<VehicleTabViewVariant>
  >;
  vehicleTabView: VehicleTabViewVariant;
};

function VehicleTabs(props: VehicleTabsProps) {
  const { vehicleTabView, setVehicleTabView } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box mx={isMobileView ? 1 : 3}>
      <MoovsTabs
        value={vehicleTabView}
        onChange={setVehicleTabView}
        variant="scrollable"
        hideBottomBorder
        indicatorWidth={0}
        tabs={[
          {
            label: "DETAILS",
            value: VehicleTabViewVariant.DETAILS,
          },
          { label: "FEATURES", value: VehicleTabViewVariant.FEATURES },
          {
            label: "PRICING",
            value: VehicleTabViewVariant.PRICING,
          },
          {
            label: "CUSTOMER PORTAL SETTINGS",
            value: VehicleTabViewVariant.BOOKING_TOOL_SETTINGS,
          },
        ]}
      />
    </Box>
  );
}

export default VehicleTabs;
