import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { Box } from "@mui/material";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import {
  PricelessBookingTarget,
  UpdateVehicleSettingsInput,
  Vehicle,
} from "types";
import {
  LOAD_COMPANIES_NAME_AND_ID_QUERY,
  LOAD_CONTACTS_QUERY,
} from "globals/graphql";
import UpdatePricelessBookingTargetDropdown from "./UpdatePricelessBookingTargetDropdown";
import { useScreenSize } from "globals/hooks";
import {
  CompanyType,
  ContactType,
  FieldNameEnum,
  SelectionType,
} from "components/vehicles/CreateVehicleDrawer/types";
import { toggleButtons } from "components/vehicles/constant";

type UpdatePricelessBookingTargetSectionProps = {
  setVehicleSettingsInput: React.Dispatch<
    React.SetStateAction<UpdateVehicleSettingsInput>
  >;
  vehicle: Partial<Vehicle>;
  vehicleSettingsInput: UpdateVehicleSettingsInput;
  setVehicle: React.Dispatch<React.SetStateAction<Partial<Vehicle>>>;
};

function UpdatePricelessBookingTargetSection(
  props: UpdatePricelessBookingTargetSectionProps
) {
  const { setVehicleSettingsInput, vehicleSettingsInput, vehicle, setVehicle } =
    props;

  const selectedCompanies: CompanyType[] =
    vehicle.settings?.pricelessBookingCompanies?.map(
      (company: CompanyType) => ({ name: company.name, id: company.id })
    ) || [];

  const selectedContacts: CompanyType[] =
    vehicle.settings?.pricelessBookingContacts?.map((contact: ContactType) => ({
      name: `${contact.firstName} ${contact.lastName}`,
      id: contact.id,
    })) || [];

  // state
  const [companySelection, setCompanySelection] =
    useState<SelectionType[]>(selectedCompanies);
  const [contactSelection, setContactSelection] =
    useState<SelectionType[]>(selectedContacts);

  // hooks
  const { isMobileView } = useScreenSize();

  // queries
  const { data: companiesData } = useQuery(LOAD_COMPANIES_NAME_AND_ID_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const { data: contactsData } = useQuery(LOAD_CONTACTS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const companyOptions: SelectionType[] =
    companiesData?.companies.map((company: CompanyType) => ({
      name: company.name,
      id: company.id,
    })) || [];

  const contactOptions: SelectionType[] =
    contactsData?.loadContacts.edges.map(
      ({ node: contact }: { node: ContactType }) => ({
        name: `${contact.firstName} ${contact.lastName}`,
        id: contact.id,
      })
    ) || [];

  if (!vehicleSettingsInput) {
    return null;
  }

  // event handlers
  const handleToggleButtonChange = (
    _: React.MouseEvent<HTMLElement>,
    value: PricelessBookingTarget
  ): void => {
    setVehicleSettingsInput({
      ...vehicleSettingsInput,
      pricelessBookingTarget: value,
    });

    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        pricelessBookingTarget: value,
      },
    });
  };

  const handleDropdownChange = (
    selections: SelectionType[],
    fieldName: FieldNameEnum
  ): void => {
    let filtered = [];
    if (fieldName === FieldNameEnum.company) {
      setCompanySelection(selections);
      filtered = companiesData?.companies?.filter((company) =>
        selections.some((item) => item.id === company.id)
      );
    } else {
      setContactSelection(selections);
      filtered = contactsData?.loadContacts?.edges
        ?.filter(({ node: contact }) =>
          selections.some((item) => item.id === contact.id)
        )
        .map(({ node: contact }) => contact);
    }

    // set up data for duplicate vehicle
    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        [fieldName === FieldNameEnum.company
          ? "pricelessBookingCompanies"
          : "pricelessBookingContacts"]: filtered,
      },
    });

    setVehicleSettingsInput({
      ...vehicleSettingsInput,
      [fieldName === FieldNameEnum.company
        ? "addPricelessBookingCompanyIds"
        : "addPricelessBookingContactIds"]: selections.map((item) => item.id),
    });
  };

  const handleDeleteDropdown = (
    item: SelectionType,
    fieldName: FieldNameEnum
  ): void => {
    const selection =
      fieldName === FieldNameEnum.company ? companySelection : contactSelection;

    const filteredSelection: SelectionType[] = selection.filter(
      (selected) => selected.id !== item.id
    );

    let filtered = [];
    if (fieldName === FieldNameEnum.company) {
      setCompanySelection(filteredSelection);
      filtered = vehicle.settings?.pricelessBookingCompanies?.filter(
        (company) => company.id !== item.id
      );
    } else {
      setContactSelection(filteredSelection);
      filtered = vehicle.settings?.pricelessBookingContacts?.filter(
        (contact) => contact.id !== item.id
      );
    }

    // set up data for duplicate vehicle
    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        [fieldName === FieldNameEnum.company
          ? "pricelessBookingCompanies"
          : "pricelessBookingContacts"]: filtered,
      },
    });

    setVehicleSettingsInput({
      ...vehicleSettingsInput,
      [fieldName === FieldNameEnum.company
        ? "removePricelessBookingCompanyIds"
        : "removePricelessBookingContactIds"]: [item.id],
    });
  };

  return (
    <>
      {vehicleSettingsInput.pricelessBookingEnabled && (
        <>
          <Box my={1}>
            <MoovsToggleButtonGroup
              onChange={handleToggleButtonChange}
              options={toggleButtons}
              value={vehicleSettingsInput.pricelessBookingTarget}
              fullWidth
              keepInline
            />
          </Box>
          <Box>
            {vehicleSettingsInput.pricelessBookingTarget ===
              PricelessBookingTarget.Specific && (
              <Box
                display="flex"
                gap={isMobileView ? 0 : 1}
                flexDirection={isMobileView ? "column" : "row"}
              >
                <Box width="100%">
                  <UpdatePricelessBookingTargetDropdown
                    options={companyOptions}
                    fieldName={FieldNameEnum.company}
                    dropdownTitle="Companies"
                    placeholder="No Company Selected"
                    selection={companySelection}
                    handleDropdownChange={handleDropdownChange}
                    handleDeleteDropdown={handleDeleteDropdown}
                  />
                </Box>
                <Box width="100%">
                  <UpdatePricelessBookingTargetDropdown
                    options={contactOptions}
                    fieldName={FieldNameEnum.contact}
                    dropdownTitle="Contacts"
                    placeholder="No Contact Selected"
                    selection={contactSelection}
                    handleDropdownChange={handleDropdownChange}
                    handleDeleteDropdown={handleDeleteDropdown}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default UpdatePricelessBookingTargetSection;
