/**
 * @file VehicleHeaderUpdateBlock.tsx
 * Goes at the top of the update vehicle drawer. Contains Vehicle name and other styling.
 */
import React from "react";
import startCase from "lodash/startCase";

import { useQuery, useMutation } from "@apollo/client";

import { Box, Chip, Typography, MenuItem } from "@mui/material";

import { SmallSelect } from "../../design-system/components/inputs";
import { grayDark } from "../../design-system/colors";
import {
  LOAD_CANCELLATION_POLICIES_QUERY,
  LOAD_INSURANCE_POLICIES_QUERY,
  UPDATE_VEHICLE_MUTATION,
  LOAD_VEHICLE_QUERY,
} from "../../globals/graphql";
import { CancellationPolicy, InsurancePolicy } from "../../types";
import AutomatedPricingIcon from "../../design-system/icons/info/AutomatedPricingIcon";
import { ExternalOperatorIcon } from "../../design-system/icons";

type VehicleHeaderUpdateBlockProps = {
  setSaveIndicatorState: Function;
  vehicleId: string;
};

function VehicleHeaderUpdateBlock(props: VehicleHeaderUpdateBlockProps) {
  const { vehicleId, setSaveIndicatorState } = props;

  // queries
  const { data: vehicleData } = useQuery(LOAD_VEHICLE_QUERY, {
    variables: {
      id: vehicleId,
    },
    skip: !vehicleId,
  });

  const { data: cancellationPoliciesData } = useQuery(
    LOAD_CANCELLATION_POLICIES_QUERY
  );

  const { data: insurancePoliciesData } = useQuery(
    LOAD_INSURANCE_POLICIES_QUERY
  );

  // mutations
  const [updateVehicle] = useMutation(UPDATE_VEHICLE_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
    },
  });

  // event handlers
  const handleChange = (event: any) => {
    setSaveIndicatorState("loading");

    const value =
      event.target.name === "available"
        ? event.target.checked
        : event.target.value;

    updateVehicle({
      variables: {
        input: {
          id: vehicleId,
          [event.target.name]: value,
        },
      },
    });
  };

  if (!vehicleData || !cancellationPoliciesData || !insurancePoliciesData) {
    return null;
  }

  const {
    name: vehicleName,
    vehicleType: { typeName },
    capacity,
    cancellationPolicy,
    insurancePolicy,
    enableBaseRateAutomation,
    externalOperator,
  } = vehicleData.node;

  return (
    <Box display="flex" flexDirection="column" my={1}>
      <Box
        my={1}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        columnGap={1}
        alignItems="center"
      >
        <Typography variant="h4">{vehicleName}</Typography>

        {enableBaseRateAutomation && (
          <Box ml={1}>
            <AutomatedPricingIcon />
          </Box>
        )}

        {externalOperator && (
          <Box display="flex" columnGap="1" alignItems="center">
            <Box>
              <ExternalOperatorIcon color={grayDark} size="small" />
            </Box>
            <Box ml={1} color={grayDark} fontSize={"12px"}>
              {externalOperator.operatorName}
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" my={1}>
        <Box mr={1}>
          <Chip label={typeName} />
        </Box>
        <Box mr={1}>
          <Chip label={`${capacity} Seats`} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" ml={-1} mt={0.5}>
        {cancellationPoliciesData && (
          <Box minWidth="220px">
            <SmallSelect
              label="CANCELLATION POLICY"
              value={cancellationPolicy ? cancellationPolicy.id : ""}
              onChange={handleChange}
              name="cancellationPolicyId"
            >
              {cancellationPoliciesData.cancellationPolicies.map(
                (cancellationPolicy: CancellationPolicy) => (
                  <MenuItem
                    key={cancellationPolicy.id}
                    value={cancellationPolicy.id}
                  >
                    {startCase(cancellationPolicy.variant.toLowerCase())}
                  </MenuItem>
                )
              )}
            </SmallSelect>
          </Box>
        )}
        {insurancePoliciesData && (
          <Box minWidth="220px">
            <SmallSelect
              label="INSURANCE POLICY"
              value={insurancePolicy ? insurancePolicy.id : ""}
              onChange={handleChange}
              name="insuranceId"
            >
              {insurancePoliciesData.insurancePolicies.map(
                (insurancePolicy: InsurancePolicy) => (
                  <MenuItem key={insurancePolicy.id} value={insurancePolicy.id}>
                    <Box>
                      {insurancePolicy.insuranceCompany}
                      <Typography
                        style={{ display: "block" }}
                        variant="caption"
                      >
                        {insurancePolicy.policyNumber}
                      </Typography>
                    </Box>
                  </MenuItem>
                )
              )}
            </SmallSelect>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default VehicleHeaderUpdateBlock;
