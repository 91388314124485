import React from "react";
import isNull from "lodash/isNull";
import isNumber from "lodash/isNumber";

import { Box, Typography, TextField, MenuItem, Switch } from "@mui/material";

import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { VehicleWeekendsBlock } from "components/vehicles/VehicleWeekendsBlock";
import { black, granite, grayDark } from "design-system/colors";
import {
  NumberFormatDollar,
  NumberFormatNatural,
} from "design-system/components/inputs/NumberFormat";
import { hourlyOptions } from "globals/utils/hourlyOptions";
import { CreateVehicle } from "../../types";
import { VehiclePricingError } from "components/vehicles/types";

type PricingProps = {
  vehicle: CreateVehicle;
  setVehicle: React.Dispatch<React.SetStateAction<CreateVehicle>>;
  setVehiclePricingErrors: React.Dispatch<
    React.SetStateAction<VehiclePricingError>
  >;
  vehiclePricingErrors: VehiclePricingError;
};

function Pricing(props: PricingProps) {
  const { vehicle, setVehicle, setVehiclePricingErrors, vehiclePricingErrors } =
    props;

  // event handlers
  const handleVehiclePriceChange = (e) => {
    let { value, name } = e.target;

    if (
      name === "weekendMinMinutes" ||
      name === "weekdayMinMinutes" ||
      name === "totalDeadheadDurationMinutes"
    ) {
      setVehicle({
        ...vehicle,
        [name]: isNull(value) ? value : Number(value),
      });

      setVehiclePricingErrors({
        ...vehiclePricingErrors,
        [name]: "",
      });

      return;
    }

    if (
      name === "minimumTotalBaseRate" ||
      name === "deadheadRatePerMile" ||
      name === "tripRatePerMile" ||
      name === "weekdayHourlyCost" ||
      name === "weekendHourlyCost"
    ) {
      // when entire input is cleared, value becomes "" and converts into 0 when using Number(), which is not what we want.
      value = value === "" ? "" : Number(value);
    }

    setVehicle({ ...vehicle, [name]: value });

    setVehiclePricingErrors({
      ...vehiclePricingErrors,
      [name]: "",
    });
  };

  const handleVehicleSettingsChange = (_, newSettings: []) => {
    setVehicle({
      ...vehicle,
      settings: { ...vehicle.settings, weekends: newSettings },
    });
  };

  const handleEnableBaseRateAutomationChange = () => {
    setVehicle({
      ...vehicle,
      enableBaseRateAutomation: !vehicle.enableBaseRateAutomation,
    });

    setVehiclePricingErrors({
      ...vehiclePricingErrors,
      weekdayHourlyCost: "",
      weekdayMinMinutes: "",
    });
  };

  return (
    <VehicleFormSection title="Pricing">
      {/* Transfer */}
      <Box mb={2}>
        <Typography variant="overline" sx={{ color: granite }}>
          transfer
        </Typography>
      </Box>
      <Box flex="1" mb={2}>
        <TextField
          required={
            isNumber(vehicle.minimumTotalBaseRate) ||
            isNumber(vehicle.deadheadRatePerMile) ||
            isNumber(vehicle.tripRatePerMile)
          }
          fullWidth
          id="minimum-total-base-rate"
          label="Minimum Total Base Rate"
          variant="outlined"
          name="minimumTotalBaseRate"
          value={vehicle.minimumTotalBaseRate}
          onChange={handleVehiclePriceChange}
          error={!!vehiclePricingErrors.minimumTotalBaseRate}
          helperText={vehiclePricingErrors.minimumTotalBaseRate}
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
        />
      </Box>

      <Box flex="1" mb={2}>
        <TextField
          required={
            isNumber(vehicle.minimumTotalBaseRate) ||
            isNumber(vehicle.deadheadRatePerMile) ||
            isNumber(vehicle.tripRatePerMile)
          }
          fullWidth
          id="deadhead-rate-per-mile"
          label="Deadhead Rate per Mile"
          variant="outlined"
          name="deadheadRatePerMile"
          value={vehicle.deadheadRatePerMile}
          onChange={handleVehiclePriceChange}
          error={!!vehiclePricingErrors.deadheadRatePerMile}
          helperText={vehiclePricingErrors.deadheadRatePerMile}
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
        />
      </Box>

      <Box flex="1" mb={2}>
        <TextField
          required={
            isNumber(vehicle.minimumTotalBaseRate) ||
            isNumber(vehicle.deadheadRatePerMile) ||
            isNumber(vehicle.tripRatePerMile)
          }
          fullWidth
          id="trip-rate-per-mile"
          label="Trip Rate per Mile"
          variant="outlined"
          name="tripRatePerMile"
          value={vehicle.tripRatePerMile}
          onChange={handleVehiclePriceChange}
          error={!!vehiclePricingErrors.tripRatePerMile}
          helperText={vehiclePricingErrors.tripRatePerMile}
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
        />
      </Box>

      {/* Hourly */}
      <Box mb={2} mt={1}>
        <Typography variant="overline" sx={{ color: granite }}>
          hourly
        </Typography>
      </Box>
      <Box mb={2}>
        <TextField
          required={
            vehicle.enableBaseRateAutomation ||
            isNumber(vehicle.weekdayHourlyCost) ||
            isNumber(vehicle.weekdayMinMinutes)
          }
          select
          fullWidth
          id="total-deadhead-duration"
          label="Total Deadhead Duration"
          variant="outlined"
          name="totalDeadheadDurationMinutes"
          value={vehicle.totalDeadheadDurationMinutes}
          onChange={handleVehiclePriceChange}
          InputProps={{
            inputComponent: NumberFormatNatural as any,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            displayEmpty: true,
          }}
        >
          {hourlyOptions.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {isNull(option) ? "No Deadhead" : `${option} Hours`}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>

      <Box flex="1" mb={2}>
        <TextField
          required={
            vehicle.enableBaseRateAutomation ||
            isNumber(vehicle.weekdayHourlyCost) ||
            isNumber(vehicle.weekdayMinMinutes)
          }
          fullWidth
          id="weekday-hourly-rate"
          label="Weekday Hourly Rate"
          variant="outlined"
          name="weekdayHourlyCost"
          value={vehicle.weekdayHourlyCost}
          onChange={handleVehiclePriceChange}
          error={!!vehiclePricingErrors.weekdayHourlyCost}
          helperText={vehiclePricingErrors.weekdayHourlyCost}
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
        />
      </Box>

      <Box flex="1" mb={2}>
        <TextField
          required={
            vehicle.enableBaseRateAutomation ||
            isNumber(vehicle.weekdayHourlyCost) ||
            isNumber(vehicle.weekdayMinMinutes)
          }
          select
          fullWidth
          id="weekday-min-minutes"
          label="Weekday Hourly Minimum"
          variant="outlined"
          name="weekdayMinMinutes"
          value={vehicle.weekdayMinMinutes}
          onChange={handleVehiclePriceChange}
          error={!!vehiclePricingErrors.weekdayMinMinutes}
          helperText={vehiclePricingErrors.weekdayMinMinutes}
          InputProps={{
            inputComponent: NumberFormatNatural as any,
          }}
        >
          {hourlyOptions.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {isNull(option) ? "None" : `${option} Hours`}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>

      <Box flex="1" mb={2}>
        <TextField
          required={
            isNumber(vehicle.weekendHourlyCost) ||
            isNumber(vehicle.weekendMinMinutes) ||
            vehicle.settings.weekends.length > 0
          }
          fullWidth
          id="weekend-hourly-rate"
          label="Weekend Hourly Rate"
          variant="outlined"
          name="weekendHourlyCost"
          value={vehicle.weekendHourlyCost}
          onChange={handleVehiclePriceChange}
          error={!!vehiclePricingErrors.weekendHourlyCost}
          helperText={vehiclePricingErrors.weekendHourlyCost}
          InputProps={{
            inputComponent: NumberFormatDollar as any,
          }}
        />
      </Box>

      <Box flex="1" mb={2}>
        <TextField
          required={
            isNumber(vehicle.weekendHourlyCost) ||
            isNumber(vehicle.weekendMinMinutes) ||
            vehicle.settings.weekends.length > 0
          }
          select
          fullWidth
          id="weekend-min-minutes"
          label="Weekend Hourly Minimum"
          variant="outlined"
          name="weekendMinMinutes"
          value={vehicle.weekendMinMinutes}
          onChange={handleVehiclePriceChange}
          error={!!vehiclePricingErrors.weekendMinMinutes}
          helperText={vehiclePricingErrors.weekendMinMinutes}
          InputProps={{
            inputComponent: NumberFormatNatural as any,
          }}
        >
          {hourlyOptions.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {isNull(option) ? "None" : `${option} Hours`}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>

      <Box mb={2}>
        <VehicleWeekendsBlock
          required={
            isNumber(vehicle.weekendHourlyCost) ||
            isNumber(vehicle.weekendMinMinutes) ||
            vehicle.settings.weekends.length > 0
          }
          error={vehiclePricingErrors.settings}
          weekendSettings={vehicle.settings.weekends}
          onChange={handleVehicleSettingsChange}
        />
      </Box>

      {/*  Base Rate Automation */}
      <Box display="flex" alignItems="start" mt={2}>
        <Box display="flex">
          <Switch
            checked={vehicle.enableBaseRateAutomation}
            onChange={handleEnableBaseRateAutomationChange}
            name="enableBaseRateAutomation"
            color="primary"
            sx={{ margin: 0 }}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box ml={1}>
            <Typography
              variant="body2"
              style={{ color: black, fontSize: "14px", fontWeight: 400 }}
            >
              Enable Base Rate Automation
            </Typography>
            <Typography variant="caption" style={{ color: grayDark }}>
              When this is on, you will have access to automated base rate when
              creating quotes and reservations.
            </Typography>
          </Box>
        </Box>
      </Box>
    </VehicleFormSection>
  );
}

export default Pricing;
