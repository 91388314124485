import React from "react";
import { useMutation } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";

import { Box } from "@mui/material";

import {
  CREATE_VEHICLE_FEATURES_MUTATION,
  REMOVE_VEHICLE_FEATURES_MUTATION,
  UPDATE_VEHICLE_CHILD_SEAT_MUTATION,
} from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { VehicleChildSeat, VehicleFeature, VehicleSettings } from "types";
import VehicleFeatures from "components/vehicles/VehicleFeatures";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import VehicleChildSeats from "components/vehicles/VehicleChildSeats/VehicleChildSeats";

type FeaturesProps = {
  vehicleId: string;
  childSeats: {
    forwardFacingSeat: VehicleChildSeat;
    rearFacingSeat: VehicleChildSeat;
    boosterSeat: VehicleChildSeat;
  };
  setChildSeats: React.Dispatch<
    React.SetStateAction<
      Pick<
        VehicleSettings,
        "rearFacingSeat" | "forwardFacingSeat" | "boosterSeat"
      >
    >
  >;
  features: VehicleFeature[];
  setFeatures: React.Dispatch<React.SetStateAction<VehicleFeature[]>>;
  setSaveIndicatorState: React.Dispatch<
    React.SetStateAction<"default" | "loading" | "saved" | "error">
  >;
};

function Features(props: FeaturesProps) {
  const {
    vehicleId,
    childSeats,
    setChildSeats,
    features,
    setFeatures,
    setSaveIndicatorState,
  } = props;

  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [createVehicleFeature] = useMutation(CREATE_VEHICLE_FEATURES_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating vehicle");
    },
  });

  const [removeVehicleFeature] = useMutation(REMOVE_VEHICLE_FEATURES_MUTATION, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error("Error updating vehicle");
    },
  });

  const [updateVehicleChildSeat] = useMutation(
    UPDATE_VEHICLE_CHILD_SEAT_MUTATION,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error("Error updating vehicle");
      },
    }
  );

  // debounced mutations
  const debouncedUpdateVehicleChildSeat = useDebouncedCallback((variables) => {
    setSaveIndicatorState("loading");
    updateVehicleChildSeat({ variables });
  }, 500);

  // event handlers
  const handleVehicleFeaturesChange =
    (modifiedFeature: VehicleFeature) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, name, category } = modifiedFeature;

      setSaveIndicatorState("loading");

      const newFeatures = event.target.checked
        ? [...features, { id, name, category }]
        : features.filter((feature: VehicleFeature) => feature.id !== id);

      setFeatures(newFeatures);

      const variables = {
        input: {
          featureId: id,
          vehicleId,
        },
      };

      event.target.checked
        ? createVehicleFeature({ variables })
        : removeVehicleFeature({ variables });
    };

  const handleVehicleChildSeatChange =
    (childSeatName: "forwardFacingSeat" | "rearFacingSeat" | "boosterSeat") =>
    (childSeat: VehicleChildSeat) => {
      // update state
      setChildSeats({
        ...childSeats,
        [childSeatName]: childSeat,
      });

      // description will be updated in state but not in the database
      if (childSeat.description?.length > 35) return;

      const variables = {
        input: {
          vehicleChildSeatId: childSeat.id,
          active: childSeat.active,
          quantity: childSeat.quantity,
          amt: childSeat.amt,
          imageUrl: childSeat.imageUrl,
          description: childSeat.description,
        },
      };

      debouncedUpdateVehicleChildSeat(variables);
    };

  return (
    <Box mt={4}>
      <VehicleFormSection title="Vehicle Features">
        <VehicleFeatures
          activeFeatures={features}
          handleVehicleFeaturesChange={handleVehicleFeaturesChange}
        />
        <VehicleChildSeats
          childSeats={childSeats}
          handleVehicleChildSeatChange={handleVehicleChildSeatChange}
        />
      </VehicleFormSection>
    </Box>
  );
}

export default Features;
