import React, { useMemo } from "react";

import { Box, Divider, useMediaQuery } from "@mui/material";

import {
  black,
  grayLight,
  moovsBlue,
  whiteTransparent,
} from "design-system/colors";
import {
  useAuth,
  useOperator,
  useScreenSize,
  useCurrentUser,
} from "globals/hooks";
import { useLocation } from "react-router-dom";
import CompanyLogo from "components/globals/CompanyLogo";
import {
  QuotesIcon,
  DispatchIcon,
  VehicleIcon,
  UsersIcon,
  InvoiceIcon,
  PayoutIcon,
  DashboardIcon,
  SettingsIcon,
  ListIcon,
  TripIcon,
  GenericCreditCardIcon,
} from "design-system/icons";
import NavCollapsedMenu from "./NavCollapsedMenu";
import NavButtonList, { NavLink } from "../../SideNavButtons/NavButtonList";
import LogoutButton from "../../SideNavButtons/LogoutButton";
import NavButton from "../../SideNavButtons/NavButton";
import { LoadQuotesConnection } from "types";
import { LOAD_NEW_QUOTES } from "globals/graphql";
import { useQuery } from "@apollo/client";

type SideNavigationContentProps = {
  onDrawerToggle?: () => void;
  showCompanyLogo?: boolean;
};

function SideNavigationContent(props: SideNavigationContentProps) {
  const { onDrawerToggle, showCompanyLogo = false } = props;

  // hooks
  const { pathname } = useLocation();
  const { authStage } = useAuth();
  const isShortScreen = useMediaQuery("( max-height: 1000px )");
  const { isMobileView } = useScreenSize();
  const operator = useOperator();
  const currentUser = useCurrentUser();

  const { data: quotes } = useQuery<{ loadNewQuotes: LoadQuotesConnection }>(
    LOAD_NEW_QUOTES,
    {
      pollInterval: 60 * 1000, // 1 minute
    }
  );

  const newQuotesCount = quotes?.loadNewQuotes.totalCount;

  // data
  const navSection2Data: NavLink[] = [
    {
      text: "Vehicles",
      icon: (
        <VehicleIcon
          color={pathname.startsWith("/vehicles") ? moovsBlue : black}
        />
      ),
      paths: ["/vehicles"],
    },
    {
      text: "Contacts",
      icon: (
        <UsersIcon
          color={
            pathname.startsWith("/contacts") ||
            pathname.startsWith("/companies") ||
            pathname.startsWith("/affiliates")
              ? moovsBlue
              : black
          }
        />
      ),
      paths: ["/contacts", "/companies", "/affiliates"],
    },
    {
      text: "Invoices",
      icon: (
        <InvoiceIcon
          color={pathname.startsWith("/invoices") ? moovsBlue : black}
        />
      ),
      paths: ["/invoices"],
    },
    {
      text: "Payables",
      icon: (
        <PayoutIcon
          color={pathname.startsWith("/payables") ? moovsBlue : black}
        />
      ),
      paths: ["/payables"],
    },
  ];

  if (currentUser?.role?.slug === "owner" && operator?.stripeAccount?.id) {
    navSection2Data.push({
      text: "Moovs Card",
      icon: (
        <GenericCreditCardIcon
          color={pathname.startsWith("/card") ? moovsBlue : black}
        />
      ),
      paths: ["/card"],
      // only owners (owner roles have full access) can see /card
      // so we don't have an AccessPermission record for this path
      permissionsOverride: true,
    });
  }

  const {
    hasAnySettingsPermissions,
    hasDashboardPermission,
    hasGeneralSettingsPermission,
    firstSettingsPath,
  } = useMemo(() => {
    const userAccessPermissions = currentUser?.accessPermissions || [];

    let hasAnySettingsPermissions = false;
    let hasDashboardPermission = false;
    let hasGeneralSettingsPermission = false;
    let firstSettingsPath = "";

    for (let permission of userAccessPermissions) {
      if (permission.category === "settings") {
        hasAnySettingsPermissions = true;
      }
      if (permission.path === "/dashboard") {
        hasDashboardPermission = true;
      }
      if (permission.path === "/settings/general") {
        hasGeneralSettingsPermission = true;
      }
      if (!firstSettingsPath && permission.category === "settings") {
        firstSettingsPath = permission.path;
      }
    }

    return {
      hasAnySettingsPermissions,
      hasDashboardPermission,
      hasGeneralSettingsPermission,
      firstSettingsPath,
    };
  }, [currentUser?.accessPermissions]);

  const shouldShowSection3 =
    hasAnySettingsPermissions || hasDashboardPermission;

  // If the member has access to General Settings, navigate to that when Settings button is clicked.
  // Otherwise, navigate to the first settings tab they have access to.
  const settingsButtonPath = hasGeneralSettingsPermission
    ? "/settings/general"
    : firstSettingsPath;

  const navSection3Data = [
    {
      text: "Dashboard",
      icon: <DashboardIcon color={black} />,
      paths: ["/dashboard"],
    },
    {
      text: "Settings",
      icon: <SettingsIcon color={black} />,
      paths: [settingsButtonPath || "/settings/general"],
      /* 
      This is just the Nav button on the Home menu to get to the Settings menu.
      Show this if the user has permissions for any settings page.
      The actual Settings => General tab is found in SettingsSideNav.tsx
      and does not have a permissions override.
      */
      permissionsOverride: hasAnySettingsPermissions,
    },
  ];

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex="1"
    >
      <Box height="100%">
        <Box
          m={3}
          display={showCompanyLogo ? "flex" : "none"}
          justifyContent="center"
        >
          <CompanyLogo />
        </Box>

        {/* nav section 1 */}
        <Box mx={1}>
          <NavButton
            itemsCount={newQuotesCount} // load new quotes here
            text="Quotes"
            active={pathname.startsWith("/quotes")}
            path="/quotes"
            onDrawerToggle={onDrawerToggle}
            icon={
              <QuotesIcon
                color={pathname.startsWith("/quotes") ? moovsBlue : black}
              />
            }
          />
          <NavButton
            text="Reservations"
            active={pathname.startsWith("/reservations")}
            path="/reservations"
            mode="reservations"
            onDrawerToggle={onDrawerToggle}
            icon={
              <TripIcon
                color={pathname.startsWith("/reservations") ? moovsBlue : black}
                fill={whiteTransparent}
              />
            }
          />
          <NavButton
            text="Dispatch"
            active={pathname.startsWith("/dispatch")}
            path="/dispatch"
            mode="reservations"
            onDrawerToggle={onDrawerToggle}
            icon={
              <ListIcon
                color={pathname.startsWith("/dispatch") ? moovsBlue : black}
              />
            }
          />
          {operator?.moovsMarketApps.driverApp.active && (
            <NavButton
              text="Driver Tracking"
              active={pathname.startsWith("/driver-tracking")}
              path="/driver-tracking"
              onDrawerToggle={onDrawerToggle}
              icon={
                <DispatchIcon
                  color={
                    pathname.startsWith("/driver-tracking") ? moovsBlue : black
                  }
                />
              }
            />
          )}
        </Box>

        <Box m={1}>
          <Divider sx={{ backgroundColor: grayLight }} />
        </Box>
        <Box mx={1}>
          <NavButtonList
            navLinks={navSection2Data}
            onDrawerToggle={onDrawerToggle}
          />
        </Box>
      </Box>
      <Box mb={1}>
        {authStage === "authenticated" && (
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="flex-end"
            alignContent="center"
          >
            {!shouldShowSection3 ? null : isShortScreen && !isMobileView ? (
              <NavCollapsedMenu
                navCollapsibleItems={
                  <NavButtonList
                    isCollapsedItem
                    navLinks={navSection3Data}
                    onDrawerToggle={onDrawerToggle}
                  />
                }
              />
            ) : (
              <Box mx={1}>
                <NavButtonList
                  navLinks={navSection3Data}
                  onDrawerToggle={onDrawerToggle}
                />
              </Box>
            )}
            <LogoutButton onDrawerToggle={onDrawerToggle} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SideNavigationContent;
