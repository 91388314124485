/**
 * @file useOperator.ts
 * Hook that abstracts operator query.
 */

import { useQuery } from "@apollo/client";

import { useSnackbar } from "./useSnackbar";
import { LOAD_OPERATOR_QUERY } from "../graphql";
import { Operator } from "../../types";
import { useCurrentUser } from "./useCurrentUser";

function useOperator(): Operator {
  // hooks
  const snackbar = useSnackbar();

  const { operatorId } = useCurrentUser() || {};

  // queries
  const { data: operatorData, error } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: {
      id: operatorId,
    },
    skip: !operatorId,
  });

  if (error) {
    snackbar.error(error.message);
  }

  return operatorData?.node || null;
}

export { useOperator };
