/**
 * @file SaveIndicatorChip.tsx
 * Design-system component for showing saving states.
 * Similar to Chip component but not built on Material UI.
 *
 * components:
 * SaveIndicatorChip
 *
 * author: jackv
 */
import React from "react";
import { useDebounce } from "use-debounce";
import moment from "moment-timezone";

import { Box, CircularProgress, Tooltip } from "@mui/material";

import { CheckIcon, WarningIcon } from "../../icons";
import { granite, errorRed, successGreen } from "../../colors";

const rootStyles = {
  cursor: "default",
  height: "32px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: "transparent",
  borderRadius: "6px",
  width: "88px",
  color: granite,
  paddingLeft: "8px",
};

type SavedIndicatorChipProps = {
  mode?: "default" | "saved" | "loading" | "error";
  updatedAt?: string;
  isIconOnly?: boolean;
};

function SavedIndicatorChip(props: SavedIndicatorChipProps) {
  const { mode = "default", updatedAt, isIconOnly } = props;

  const [debouncedMode] = useDebounce(mode, 1000, { leading: true });

  const modesMap = {
    default: {
      label: "Saved",
      icon: <CheckIcon />,
    },
    saved: {
      label: "Saved",
      icon: <CheckIcon color={successGreen} />,
    },
    loading: {
      label: "Saving",
      icon: (
        <CircularProgress
          size={15}
          style={{ marginLeft: "2px" }}
          color="primary"
        />
      ),
    },
    error: {
      label: "Error",
      icon: <WarningIcon size="small" color={errorRed} />,
    },
  };

  return (
    <Tooltip
      enterDelay={200}
      enterNextDelay={200}
      title={updatedAt ? `Saved ${moment(updatedAt).fromNow()}` : ""}
    >
      <Box component="div" sx={rootStyles}>
        <Box component="span" display="flex" width="20px">
          {modesMap[mode === "error" ? mode : debouncedMode].icon}
        </Box>
        {!isIconOnly && (
          <Box component="span" marginLeft="8px" fontSize="small">
            {modesMap[mode === "error" ? mode : debouncedMode].label}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

export default SavedIndicatorChip;
