import React from "react";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";

import { Box } from "@mui/material";

import VehiclePricingBlock from "components/vehicles/VehiclePricingBlock";
import { getBRAVehiclePricingErrors } from "../../util";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { useAnalytics } from "globals/hooks";
import { Vehicle } from "types";
import { VehiclePricingError } from "components/vehicles/types";

type PricingProps = {
  vehicle: Partial<Vehicle>;
  setVehicle: React.Dispatch<React.SetStateAction<Partial<Vehicle>>>;
  vehiclePricingErrors: VehiclePricingError;
  setVehiclePricingErrors: React.Dispatch<
    React.SetStateAction<VehiclePricingError>
  >;
  setSaveIndicatorState: React.Dispatch<
    React.SetStateAction<"default" | "loading" | "saved" | "error">
  >;
  setPublishedIndicatorState: React.Dispatch<
    React.SetStateAction<"default" | "error" | "publishing" | "published">
  >;
};

function Pricing(props: PricingProps) {
  const {
    vehicle,
    setVehicle,
    setVehiclePricingErrors,
    vehiclePricingErrors,
    setSaveIndicatorState,
    setPublishedIndicatorState,
  } = props;

  // hooks
  const { track } = useAnalytics();

  // event handlers
  const handleVehicleInfoChange = (event) => {
    let { value, name, checked } = event.target;

    setVehiclePricingErrors({ ...vehiclePricingErrors, [name]: "" });

    if (
      name === "weekendMinMinutes" ||
      name === "weekdayMinMinutes" ||
      name === "totalDeadheadDurationMinutes"
    ) {
      value = isNull(value) ? value : Number(value) * 60; // convert hours to minutes
    }

    if (name === "enableBaseRateAutomation") {
      if (checked === true) {
        const pricingErrors = getBRAVehiclePricingErrors(vehicle);

        if (!isEmpty(pricingErrors)) {
          setVehiclePricingErrors(pricingErrors);
          return;
        }

        track("vehicle_braEnabled");
      }

      value = checked;
    }

    if (
      name === "minimumTotalBaseRate" ||
      name === "deadheadRatePerMile" ||
      name === "tripRatePerMile" ||
      name === "weekdayHourlyCost" ||
      name === "weekendHourlyCost"
    ) {
      value = value === "" ? null : Number(value);
    }

    setVehicle({
      ...vehicle,
      [name]: value,
    });
  };

  const handleVehicleSettingsChange = (newWeekendSettings) => {
    setVehicle({
      ...vehicle,
      settings: { ...vehicle.settings, weekends: newWeekendSettings },
    });
  };

  return (
    <Box mt={4}>
      <VehicleFormSection title="Pricing">
        <VehiclePricingBlock
          vehicle={vehicle}
          onVehicleInfoChange={handleVehicleInfoChange}
          vehiclePricingErrors={vehiclePricingErrors}
          setSaveIndicatorState={setSaveIndicatorState}
          setPublishedIndicatorState={setPublishedIndicatorState}
          onUpdateVehicleSettings={handleVehicleSettingsChange}
        />
      </VehicleFormSection>
    </Box>
  );
}

export default Pricing;
