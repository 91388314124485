import { gql } from "@apollo/client";

export const SEND_DRIVER_ASSIGN_NOTIFICATION = gql`
  mutation SendDriverAssignTextOrNotification(
    $input: SendDriverAssignTextOrNotificationInput!
  ) {
    sendDriverAssignTextOrNotification(input: $input) {
      __typename
    }
  }
`;
