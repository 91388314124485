import React from "react";
import { useQuery } from "@apollo/client";

import { Box, MenuItem } from "@mui/material";

import VehiclePhotos from "components/vehicles/VehiclePhotos/VehiclePhotos";
import {
  LabeledInlineInput,
  LabeledInlineSelect,
} from "design-system/components/inputs";
import {
  getVehicleTypeNameByTypeSlug,
  vehicleIconByIconCategory,
} from "globals/utils/vehicleTypes";
import theme from "theme";
import { Vehicle, VehiclePhoto, VehicleType } from "types";
import { LOAD_VEHICLE_TYPES_QUERY } from "globals/graphql";
import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { VehiclePricingError } from "components/vehicles/types";

type DetailsProps = {
  vehicle: Partial<Vehicle>;
  setVehicle: React.Dispatch<React.SetStateAction<Partial<Vehicle>>>;
  vehiclePricingErrors: VehiclePricingError;
  setVehiclePricingErrors: React.Dispatch<
    React.SetStateAction<VehiclePricingError>
  >;
  photos: VehiclePhoto[];
  handleVehiclePhotosChange: (newPhotos: VehiclePhoto[]) => void;
  handleResetPhotos: () => void;
  setSaveIndicatorState: React.Dispatch<
    React.SetStateAction<"default" | "loading" | "saved" | "error">
  >;
};

function Details(props: DetailsProps) {
  const {
    vehicle,
    setVehicle,
    vehiclePricingErrors,
    setVehiclePricingErrors,
    handleVehiclePhotosChange,
    handleResetPhotos,
    photos,
    setSaveIndicatorState,
  } = props;

  // query
  const { data: vehicleTypesData } = useQuery(LOAD_VEHICLE_TYPES_QUERY);

  const vehicleTypes = vehicleTypesData ? vehicleTypesData.vehicleTypes : [];

  // event handler
  const handleDetailsChange = (e) => {
    let { value, name } = e.target;

    setVehiclePricingErrors({ ...vehiclePricingErrors, [name]: "" });

    if (name === "vehicleType") {
      value = vehicleTypes.find(
        (vehicleType: VehicleType) => vehicleType.typeSlug === value
      );
    }

    setVehicle({ ...vehicle, [name]: value });
  };

  return (
    <Box mt={4}>
      {/* Basic Info */}
      <VehicleFormSection title="Basic Info">
        <LabeledInlineInput
          required
          name="name"
          errorText="Vehicle name required"
          label="Name"
          value={vehicle.name}
          onChange={handleDetailsChange}
        />
        <LabeledInlineSelect
          name="vehicleType"
          label="Type"
          value={vehicle?.vehicleType?.typeSlug || ""}
          onChange={handleDetailsChange}
          renderValue={(value) =>
            getVehicleTypeNameByTypeSlug(value, vehicleTypes)
          }
        >
          {vehicleTypes.map(
            ({ typeName, typeSlug, iconCategory }: VehicleType) => (
              <MenuItem
                key={typeSlug}
                value={typeSlug}
                sx={{
                  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
                }}
              >
                <Box mr={1.5} position="relative" top="2px">
                  {iconCategory && vehicleIconByIconCategory()[iconCategory]}
                </Box>
                {typeName}
              </MenuItem>
            )
          )}
        </LabeledInlineSelect>
        <LabeledInlineInput
          required
          name="capacity"
          errorText="Vehicle passenger capacity required"
          label="Passenger Capacity"
          value={vehicle.capacity}
          onChange={handleDetailsChange}
        />
        <LabeledInlineInput
          name="licensePlate"
          label="License Plate"
          value={vehicle.licensePlate}
          inputProps={{ maxLength: 8 }}
          onChange={handleDetailsChange}
        />
        <LabeledInlineInput
          multiline
          name="description"
          label="Description"
          value={vehicle.description}
          onChange={handleDetailsChange}
        />
        <LabeledInlineInput
          name="exteriorColor"
          label="Exterior Color"
          value={vehicle.exteriorColor}
          onChange={handleDetailsChange}
        />
        <LabeledInlineInput
          name="vinNumber"
          label="VIN Number"
          value={vehicle.vinNumber}
          onChange={handleDetailsChange}
        />
      </VehicleFormSection>

      {/* Photos */}
      <VehicleFormSection
        title="Photos"
        subTitle=".jpg and .png files"
        tooltipText="Drag to reorder photos. The first photo is your featured image"
      >
        <VehiclePhotos
          mode="update"
          photos={photos}
          vehicleId={vehicle.id}
          onVehiclePhotosChange={handleVehiclePhotosChange}
          setSaveIndicatorState={setSaveIndicatorState}
          onResetPhotos={handleResetPhotos}
        />
      </VehicleFormSection>
    </Box>
  );
}

export default Details;
