import { useMemo, Dispatch, SetStateAction } from "react";
import map from "lodash/map";
import isNull from "lodash/isNull";
import size from "lodash/size";
import filter from "lodash/filter";

import {
  GridColumnOrderChangeParams,
  GridColumnVisibilityChangeParams,
  GridApiRef,
  GridColumnResizeParams,
} from "@mui/x-data-grid-pro";

import { useUserSettings } from "globals/hooks/useUserSettings";
import { useSnackbar } from "globals/hooks";
import { move } from "globals/utils/move";
import { mergeDataGridConfigs } from "../utils";
import useUpdateTripsViewConfigMutation from "./useUpdateTripsViewConfigMutation";

type UseTripsViewConfigParams = {
  apiRef: GridApiRef;
  setSaveIndicatorState?: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  refetchTripsData?: () => void;
};

function useTripsViewConfig(params: UseTripsViewConfigParams) {
  const { apiRef, setSaveIndicatorState, refetchTripsData } = params;

  // hooks
  const snackbar = useSnackbar();
  const userSettings = useUserSettings();
  const { onUpdateTripsViewConfig } = useUpdateTripsViewConfigMutation();

  // derived state
  const columns = useMemo(() => {
    if (isNull(userSettings)) return [];

    return mergeDataGridConfigs(
      setSaveIndicatorState,
      refetchTripsData,
      userSettings.settings.tripsViewConfig?.config
    );
  }, [userSettings, setSaveIndicatorState, refetchTripsData]);

  const userConfig = useMemo(
    () => map(columns, ({ field, hide, width }) => ({ field, hide, width })),
    [columns]
  );

  // event handlers
  const handleColumnVisibilityChange = (
    params: GridColumnVisibilityChangeParams
  ) => {
    const { field, isVisible, colDef } = params;
    const visibleColumns = filter(columns, (column) => !column.hide);

    // undo hiding if only one column is showing
    if (colDef.hide && size(visibleColumns) === 1) {
      snackbar.error("Can't hide all columns. One must be visible.");

      apiRef.current.setColumnVisibility(field, true);
      return;
    }

    const updatedConfig = map(userConfig, (column) => {
      if (column.field !== field) return column;

      return {
        ...column,
        hide: !isVisible,
      };
    });

    onUpdateTripsViewConfig(updatedConfig);
  };

  const handleShowAllColumnsClick = () => {
    const updatedConfig = map(userConfig, (column) => ({
      ...column,
      hide: false,
    }));

    onUpdateTripsViewConfig(updatedConfig);
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { oldIndex, targetIndex } = params;
    const updatedConfig = move(columns, oldIndex, targetIndex);
    onUpdateTripsViewConfig(updatedConfig);
  };

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    const { width, colDef } = params;

    const updatedConfig = map(userConfig, (column) => {
      if (column.field !== colDef.field) return column;

      return {
        ...column,
        width,
      };
    });

    onUpdateTripsViewConfig(updatedConfig);
  };

  return {
    columns,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onShowAllColumnsClick: handleShowAllColumnsClick,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnWidthChange: handleColumnWidthChange,
  };
}

export { useTripsViewConfig };
