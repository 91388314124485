/**
 * @file Router.tsx
 * Main router for site.
 *
 * components:
 * Router
 */

import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

// pages
import FourZeroFourPage from "pages/404Page";
import DeactivatedPage from "pages/DeactivatedPage";
import PrivateRoute from "routes/PrivateRoute";
import LoadingPage from "pages/LoadingPage";
import NoAccessPage from "pages/NoAccessPage";

import Layout from "design-system/components/layout/Layout";
import AuthRouter from "./AuthRouter";
import OnboardingRouter from "./OnboardingRouter";
import MainRouter from "./MainRouter";
import DrawerRouter from "./DrawerRouter";
import { useCohereInit } from "globals/hooks/useCohereInit";
import { useIdentifyAnalytics } from "globals/hooks/useIdentifyAnalytics";
import { useUserLeapInit } from "globals/hooks/useUserLeapInit";
import { useIdentifyLDClient } from "globals/hooks";

function OperatorRouter() {
  // hooks
  const location = useLocation();
  useCohereInit();
  useIdentifyAnalytics({ init: true });
  useUserLeapInit();
  useIdentifyLDClient();

  return (
    <>
      <Switch location={location}>
        {/* authentication layout */}
        <Route
          exact
          path={[
            "/login",
            "/netflix/login",
            "/sign-up",
            "/join-team/:userId",
            "/forgot-password",
          ]}
        >
          <AuthRouter />
        </Route>

        {/* onboarding */}
        <PrivateRoute path="/onboarding" permissionsOverride>
          <OnboardingRouter />
        </PrivateRoute>

        {/* no route */}
        <PrivateRoute exact path="/" permissionsOverride>
          <LoadingPage />
        </PrivateRoute>

        {/* MAIN LAYOUT PAGES */}
        <Route
          path={[
            "/quotes",
            "/reservations",
            "/dispatch",
            "/new-dispatch",
            "/vehicles",
            "/invoices",
            "/dashboard",
            "/contacts",
            "/companies",
            "/affiliates",
            "/payables",
            "/settings",
            "/driver-tracking",
            "/getting-started",
            "/card",
            "/chat", // Blank page - in case user's only home permission is chat
          ]}
        >
          <Layout>
            <MainRouter />
          </Layout>
        </Route>

        <Route path="/deactivated">
          <DeactivatedPage />
        </Route>
        <Route path="/no-access">
          <NoAccessPage />
        </Route>
        <Route path="/*">
          <FourZeroFourPage />
        </Route>
      </Switch>

      {/* Drawer Routes */}
      {/* NOTE: We are using react-router-dom v5 which supports the use of the 'exact' attribute on Route components.
      Having 'exact' on a Route means the path has to match exactly to enter that Route. Not having 'exact' means the 
      path just needs to be included to enter that route (an exact match is not necessary).

      Since we don't use the 'exact' attribute in the Route for the MAIN LAYOUT PAGES above, we do not have to explicitly
      implement the check for a member's access permissions in the PrivateDrawerRoute in addition to checking it in the PrivateRoute.
      This is because, for example, navigating to "/quotes/:requestId/add-trip" would also enter the PrivateRoute for "/quotes",
      where we check a member's access permissions.

      react-router-dom v6 does NOT support the 'exact' attribute; all Routes use exact BY DEFAULT.
      Which means if we ever upgrade to v6, we would have to, in addition to other fixes,
      add member permissions checks to the PrivateDrawerRoute.
      
      See https://stackoverflow.com/questions/69866581/property-exact-does-not-exist-on-type
      */}
      <DrawerRouter />
    </>
  );
}

export default OperatorRouter;
