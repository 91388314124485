import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_SETTINGS_MUTATION = gql`
  mutation UpdateVehicleSettings($input: UpdateVehicleSettingsInput!) {
    updateVehicleSettings(input: $input) {
      vehicleSettings {
        id
        weekends
        pricelessBookingEnabled
        pricelessBookingTarget
      }
    }
  }
`;
