import { Dispatch, SetStateAction } from "react";
import filter from "lodash/filter";
import map from "lodash/map";
import find from "lodash/find";

import { GridColDef } from "@mui/x-data-grid-pro";

import { dataGridDefaultConfig } from ".";

export const mergeDataGridConfigs = (
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >,

  refetchTripsData?: () => void,
  userConfig?: GridColDef[]
) => {
  const dataGridDefaultConfigValues = dataGridDefaultConfig(
    setSaveIndicatorState,
    refetchTripsData
  );
  // if no userConfig use defaults
  if (!userConfig) return dataGridDefaultConfigValues;

  // filter out configs not in defaults (i.e. columns we removed)
  const userConfigValues = filter(
    userConfig,
    ({ field }) =>
      !!find(dataGridDefaultConfigValues, (cell) => cell.field === field)
  );

  // filter out columns in default that ARE in userConfig (i.e. columns we have since added)
  const defaultColumnssNotInUserConfig = filter(
    dataGridDefaultConfigValues,
    ({ field }) => !find(userConfig, (cell) => cell.field === field)
  );

  // merge userConfigColumns with defaultColumns
  return map(
    [...userConfigValues, ...defaultColumnssNotInUserConfig],
    (userConfigCell) => {
      const defaultColumn = find(
        dataGridDefaultConfigValues,
        (cell) => cell.field === userConfigCell.field
      );

      return { ...defaultColumn, ...userConfigCell };
    }
  );
};
