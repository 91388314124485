import isNull from "lodash/isNull";

import { Vehicle } from "types";
import { VehiclePricingError } from "../types";

export const getUpdateVehiclePricingErrors = (vehicle: Partial<Vehicle>) => {
  let errors: VehiclePricingError = {};

  // transfer pricing logic coupling
  if (
    !isNull(vehicle.minimumTotalBaseRate) ||
    !isNull(vehicle.deadheadRatePerMile) ||
    !isNull(vehicle.tripRatePerMile)
  ) {
    if (isNull(vehicle.minimumTotalBaseRate)) {
      errors = {
        ...errors,
        minimumTotalBaseRate:
          "Minimum total base rate required to use transfer pricing",
      };
    }

    if (isNull(vehicle.deadheadRatePerMile)) {
      errors = {
        ...errors,
        deadheadRatePerMile:
          "Deadhead rate per mile required to use transfer pricing",
      };
    }
    if (isNull(vehicle.tripRatePerMile)) {
      errors = {
        ...errors,
        tripRatePerMile: "Trip rate required to use transfer pricing",
      };
    }
  }

  // hourly pricing logic coupling
  if (
    !isNull(vehicle.weekdayHourlyCost) ||
    !isNull(vehicle.weekdayMinMinutes)
  ) {
    if (isNull(vehicle.weekdayHourlyCost)) {
      errors = {
        ...errors,
        weekdayHourlyCost: "Weekday hourly rate required to use hourly pricing",
      };
    }

    if (isNull(vehicle.weekdayMinMinutes)) {
      errors = {
        ...errors,
        weekdayMinMinutes:
          "Weekday hourly minimum required to use hourly pricing",
      };
    }
  }

  // hourly pricing weekend logic coupling
  if (
    !isNull(vehicle.weekendHourlyCost) ||
    !isNull(vehicle.weekendMinMinutes) ||
    vehicle.settings.weekends.length > 0
  ) {
    if (isNull(vehicle.weekendHourlyCost)) {
      errors = {
        ...errors,
        weekendHourlyCost: "Weekend hourly cost required to use hourly pricing",
      };
    }

    if (isNull(vehicle.weekendMinMinutes)) {
      errors = {
        ...errors,
        weekendMinMinutes:
          "Weekend hourly minimum required to use hourly pricing",
      };
    }

    if (vehicle.settings.weekends.length === 0) {
      errors = {
        ...errors,
        settings: "Select weekends to use weekend hourly pricing",
      };
    }
  }

  return errors;
};

export const getBRAVehiclePricingErrors = (vehicle: Partial<Vehicle>) => {
  let errors: VehiclePricingError = {};

  if (
    !isNull(vehicle.minimumTotalBaseRate) ||
    !isNull(vehicle.deadheadRatePerMile) ||
    !isNull(vehicle.tripRatePerMile)
  ) {
    if (isNull(vehicle.minimumTotalBaseRate)) {
      errors = {
        ...errors,
        minimumTotalBaseRate:
          "Clear or set transfer fields to enable base rate automation",
      };
    }

    if (isNull(vehicle.deadheadRatePerMile)) {
      errors = {
        ...errors,
        deadheadRatePerMile:
          "Clear or set transfer fields to enable base rate automation",
      };
    }

    if (isNull(vehicle.tripRatePerMile)) {
      errors = {
        ...errors,
        tripRatePerMile:
          "Clear or set transfer fields to enable base rate automation",
      };
    }
  }

  if (
    !isNull(vehicle.weekendHourlyCost) ||
    !isNull(vehicle.weekendMinMinutes) ||
    vehicle.settings.weekends.length > 0
  ) {
    if (isNull(vehicle.weekendHourlyCost)) {
      errors = {
        ...errors,
        weekendHourlyCost:
          "All weekend fields required for base rate automation",
      };
    }

    if (isNull(vehicle.weekendMinMinutes)) {
      errors = {
        ...errors,
        weekendMinMinutes:
          "All weekend fields required for base rate automation",
      };
    }

    if (vehicle.settings.weekends.length === 0) {
      errors = {
        ...errors,
        settings: "All weekend fields required for base rate automation",
      };
    }
  }

  if (isNull(vehicle.weekdayHourlyCost)) {
    errors = {
      ...errors,
      weekdayHourlyCost:
        "Weekday hourly rate required for base rate automation",
    };
  }
  if (isNull(vehicle.weekdayMinMinutes)) {
    errors = {
      ...errors,
      weekdayMinMinutes:
        "Weekday hourly minimum required for base rate automation",
    };
  }

  return errors;
};
