import React, { ChangeEvent, useState } from "react";
import isNumber from "lodash/isNumber";
import isNull from "lodash/isNull";

import { Typography, Box, MenuItem, Switch } from "@mui/material";

import { grayDark, granite, grayLight, black } from "design-system/colors";
import { InfoIcon } from "design-system/icons";
import {
  DollarInputLabeledInline,
  LabeledInlineSelect,
} from "design-system/components/inputs";
import { hourlyOptions } from "globals/utils/hourlyOptions";
import { VehicleWeekendsBlock } from "./VehicleWeekendsBlock";
import { LabelWrapper } from "design-system/components/inputs/inline/LabeledInlineInputs";
import { useUpdateVehicleSettingsMutation } from "./hooks/useUpdateVehicleSettingsMutation";
import { FarmAffiliateVehicle, Vehicle } from "types";
import { VehiclePricingError } from "./types";
import { VehiclePricingInitialErrors } from "./constant";

const styles = {
  sectionSubTitle: {
    color: granite,
  },
};

type VehiclePricingBlockProps = {
  vehicle: Partial<Vehicle | FarmAffiliateVehicle>;
  isReadOnly?: boolean;
  onVehicleInfoChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  vehiclePricingErrors?: VehiclePricingError;
  setSaveIndicatorState?: (
    value: "default" | "loading" | "saved" | "error"
  ) => void;
  setPublishedIndicatorState?: (
    value: "default" | "publishing" | "published" | "error"
  ) => void;
  onUpdateVehicleSettings?: (newSettings: any) => void;
};

function VehiclePricingBlock(props: VehiclePricingBlockProps) {
  const {
    vehicle,
    isReadOnly,
    onVehicleInfoChange,
    vehiclePricingErrors = VehiclePricingInitialErrors,
    setSaveIndicatorState,
    setPublishedIndicatorState,
    onUpdateVehicleSettings,
  } = props;

  // derived state
  const vehiclePricing = {
    weekendHourlyCost: vehicle.weekendHourlyCost,
    weekdayHourlyCost: vehicle.weekdayHourlyCost,
    weekendMinMinutes: vehicle.weekendMinMinutes,
    weekdayMinMinutes: vehicle.weekdayMinMinutes,
    minimumTotalBaseRate: vehicle.minimumTotalBaseRate,
    deadheadRatePerMile: vehicle.deadheadRatePerMile,
    tripRatePerMile: vehicle.tripRatePerMile,
    totalDeadheadDurationMinutes: vehicle.totalDeadheadDurationMinutes,
    enableBaseRateAutomation: vehicle.enableBaseRateAutomation,
    settings: vehicle.settings,
  };

  const isDisabled = isReadOnly || vehiclePricing.enableBaseRateAutomation;

  // state
  const [vehicleSettingsInput, setVehicleSettingsInput] = useState(null);

  // hooks
  // this is a hook that contains the logic to debounce and update vehicle settings
  useUpdateVehicleSettingsMutation({
    setSaveIndicatorState,
    setPublishedIndicatorState,
    vehicle,
    setVehicleSettingsInput,
    vehiclePricingErrors,
    vehicleSettingsInput,
  });

  // event handlers
  const handleVehicleSettingsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newWeekends: [{ name: string; value: string; index: number }]
  ) => {
    onUpdateVehicleSettings(newWeekends);
    setVehicleSettingsInput({ ...vehicle.settings, weekends: newWeekends });
  };

  return (
    <>
      <Box mb={2} display="flex" flexDirection="column">
        <Box mb={2}>
          {isReadOnly && <Typography variant="h4">Pricing</Typography>}
          {vehiclePricing.enableBaseRateAutomation && !isReadOnly && (
            <Box
              mt={1}
              p={2}
              flexDirection="row"
              alignItems="center"
              display="flex"
              flex="1"
              bgcolor={grayLight}
              color={grayDark}
              borderRadius="4px"
            >
              <InfoIcon color={black} />
              <Box ml="14px" display="flex" alignItems="flex-start">
                <Typography style={{ fontSize: "12px", color: granite }}>
                  Disable base rate automation to edit pricing fields.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Typography variant="overline" sx={styles.sectionSubTitle}>
          transfer
        </Typography>
        <DollarInputLabeledInline
          name="minimumTotalBaseRate"
          required={!!vehiclePricingErrors.minimumTotalBaseRate}
          disabled={isDisabled}
          label="Minimum Total Base Rate"
          labelSize="large"
          value={vehiclePricing.minimumTotalBaseRate}
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
            errorText: vehiclePricingErrors.minimumTotalBaseRate,
            error: !!vehiclePricingErrors.minimumTotalBaseRate,
          })}
        />
        <DollarInputLabeledInline
          name="deadheadRatePerMile"
          required={!!vehiclePricingErrors.deadheadRatePerMile}
          disabled={isDisabled}
          label="Deadhead Rate per Mile"
          labelSize="large"
          value={vehiclePricing.deadheadRatePerMile}
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
            errorText: vehiclePricingErrors.deadheadRatePerMile,
            error: !!vehiclePricingErrors.deadheadRatePerMile,
          })}
        />
        <DollarInputLabeledInline
          name="tripRatePerMile"
          required={!!vehiclePricingErrors.tripRatePerMile}
          disabled={isDisabled}
          label="Trip Rate per Mile"
          labelSize="large"
          value={vehiclePricing.tripRatePerMile}
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
            errorText: vehiclePricingErrors.tripRatePerMile,
            error: !!vehiclePricingErrors.tripRatePerMile,
          })}
        />
        <Box my={1}>
          <Typography variant="overline" sx={styles.sectionSubTitle}>
            hourly
          </Typography>
        </Box>

        <LabeledInlineSelect
          name="totalDeadheadDurationMinutes"
          disabled={isDisabled}
          label="Total Deadhead Duration"
          labelSize="large"
          value={
            isNumber(vehiclePricing.totalDeadheadDurationMinutes)
              ? vehiclePricing.totalDeadheadDurationMinutes / 60
              : null
          }
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
          })}
        >
          {hourlyOptions.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {isNull(option) ? "No Deadhead" : `${option} Hours`}
              </MenuItem>
            );
          })}
        </LabeledInlineSelect>

        <DollarInputLabeledInline
          name="weekdayHourlyCost"
          required={!!vehiclePricingErrors.weekdayHourlyCost}
          disabled={isDisabled}
          label="Weekday Hourly Rate"
          labelSize="large"
          value={vehiclePricing.weekdayHourlyCost}
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
            errorText: vehiclePricingErrors.weekdayHourlyCost,
            error: !!vehiclePricingErrors.weekdayHourlyCost,
          })}
        />
        <LabeledInlineSelect
          name="weekdayMinMinutes"
          required={!!vehiclePricingErrors.weekdayMinMinutes}
          disabled={isDisabled}
          label="Weekday Hourly Minimum"
          labelSize="large"
          value={
            isNumber(vehiclePricing.weekdayMinMinutes) &&
            vehiclePricing.weekdayMinMinutes / 60
          }
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
            errorText: vehiclePricingErrors.weekdayMinMinutes,
            error: !!vehiclePricingErrors.weekdayMinMinutes,
          })}
        >
          {hourlyOptions.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {isNull(option) ? "None" : `${option} Hours`}
              </MenuItem>
            );
          })}
        </LabeledInlineSelect>

        <DollarInputLabeledInline
          name="weekendHourlyCost"
          required={!!vehiclePricingErrors.weekendHourlyCost}
          disabled={isDisabled}
          label="Weekend Hourly Rate"
          labelSize="large"
          value={vehiclePricing.weekendHourlyCost}
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
            errorText: vehiclePricingErrors.weekendHourlyCost,
            error: !!vehiclePricingErrors.weekendHourlyCost,
          })}
        />
        <LabeledInlineSelect
          required={!!vehiclePricingErrors.weekendMinMinutes}
          disabled={isDisabled}
          label="Weekend Hourly Minimum"
          labelSize="large"
          value={
            isNumber(vehiclePricing.weekendMinMinutes) &&
            vehiclePricing.weekendMinMinutes / 60
          }
          name="weekendMinMinutes"
          {...(!isReadOnly && {
            onChange: onVehicleInfoChange,
            errorText: vehiclePricingErrors.weekendMinMinutes,
            error: !!vehiclePricingErrors.weekendMinMinutes,
          })}
        >
          {hourlyOptions.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {isNull(option) ? "None" : `${option} Hours`}
              </MenuItem>
            );
          })}
        </LabeledInlineSelect>

        <LabelWrapper
          inputName="Vehicle Settings"
          label="Choose Your Weekends"
          value="Vehicle Settings"
          size="large"
        >
          <VehicleWeekendsBlock
            transparent
            disabled={isDisabled}
            required={!!vehiclePricingErrors.settings}
            error={vehiclePricingErrors.settings}
            weekendSettings={vehicleSettingsInput?.weekends}
            onChange={handleVehicleSettingsChange}
          />
        </LabelWrapper>
      </Box>
      {!isReadOnly && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" ml={-1}>
            <Switch
              checked={vehiclePricing.enableBaseRateAutomation}
              onChange={onVehicleInfoChange}
              name="enableBaseRateAutomation"
              color="primary"
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography
                variant="caption"
                style={{ color: granite, fontSize: "14px", fontWeight: 500 }}
              >
                Enable Base Rate Automation
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default VehiclePricingBlock;
